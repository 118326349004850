<template>
  <div class="allYearActivity">
    <slideshow :slideshowList="slideshowList"></slideshow>
    <img class="debris" src="~static/debris.png" />
    <img class="Aboutbg" style="top: 82%; right: 0" src="~static/crane.png" />
    <img class="Aboutbg" style="top: 22%; right: 0" src="~static/crane.png" />
    <img class="Aboutbg" style="top: 56%; left: 0" src="~static/wasteL.png" />
    <img class="Aboutbg" style="bottom: 0; left: 0" src="~static/wasteL.png" />
    <img class="Aboutbg" style="top: 53%; right: 0" src="~static/wasteR.png" />
    <el-container>
      <el-main>
        <div class="title-top">
          <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
            <img src="~static/scenery/home.png" alt="" />
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>全年活动</el-breadcrumb-item>
          </el-breadcrumb> -->
          <img
            class="home-icon"
            src="~static/scenery/home.png"
            alt=""
            style="width: 20; height: 20"
          />
          <span
            style="margin-right: 6px; font-weight: bold"
            @click="Jump('/', 0)"
            >首页</span
          >
          >
          <span style="margin-left: 6px" @click="Jump('/goodsActivity')"
            >玩转条子泥</span
          >
          >
          <span style="margin-left: 6px; color: #509ab1">全年活动</span>
        </div>
        <div class="content-middle">
          <div class="titlt-box">
            <div class="content-middle-item">全年活动</div>
          </div>

          <div class="content-table">
            <div
              class="content-table-item-box"
              v-for="(item, index) in list"
              :key="index"
            >
              <div class="content-table-item-bx">
                <div class="content-table-item">
                  <div class="content-table-left">
                    <span class="content-table-left-time">{{
                      item.startTime.slice(8, 10)
                    }}</span>
                    <span class="content-table-left-timer"
                      >{{ item.startTime.slice(5, 7) }}月</span
                    >
                  </div>
                  <div class="content-table-center">
                    <div>
                      <img
                        v-if="item.status == 0"
                        src="~static/activityAll/new-icon.png"
                        alt=""
                      />
                      <span class="title">{{ item.name }}</span>
                    </div>
                    <p>
                      {{ item.introduce }}
                    </p>
                  </div>
                  <div class="content-table-right" v-if="item.timer == 0">
                    <p>活动未启</p>
                  </div>
                  <div
                    class="content-table-right oneps"
                    v-else-if="item.timer == 1"
                  >
                    <p>活动结束</p>
                  </div>
                  <el-popover
                    v-else
                    placement="bottom"
                    title="扫描二维码报名"
                    width="200"
                    trigger="click"
                  >
                    <img width="200" src="~static/qr.png" />
                    <div slot="reference" class="content-table-right twops">
                      <p>活动报名</p>
                    </div>
                  </el-popover>
                </div>
              </div>
              <el-divider></el-divider>
            </div>

            <!-- 分页 -->
            <div class="pagination-box">
              <el-button type="button" class="my-btn">首页</el-button>
              <el-pagination
                ref="pagination"
                background
                next-text="下一页"
                layout=" pager, next, slot"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :total="total"
                style="display: inline-block; padding-left: 0px"
              >
              </el-pagination>
            </div>
          </div>
        </div>

        <!-- 侧边导航栏 -->
        <leftFloat :floatList="floatList" />
      </el-main>
    </el-container>
  </div>
</template>

<script>
import * as api from "@/api/test/test";
import { tzn_allYear } from "@/api/test/scenery";
export default {
  data() {
    return {
      floatList: {
        list: [
          { name: "精品活动", url: "/goodsActivity" },
          { name: "全年活动", url: "flush" },
        ],
        title: "玩转条子泥",
      },
      slideshowList: ``,
      info: {
        page: 0,
        size: 10,
      },
      list: [],
      total: null,
      timer: Date.now(),
    };
  },
  created() {
    api.slideshow().then((res) => {
      this.slideshowList = res;
    });
  },
  methods: {
    handleSizeChange(val) {
      this.info.size = val;
      this.tzn_allYear();
    },
    handleCurrentChange(val) {
      this.info.page = val - 1;
      this.tzn_allYear();
    },
    async tzn_allYear() {
      const res = await tzn_allYear(this.info);
      this.list = res.data;
      this.list.forEach((item) => {
        let start = new Date(item.startTime).getTime();
        let end = new Date(item.endTime).getTime();
        console.log(start, end);
        if (start > this.timer) {
          item.timer = 0;
        } else if (end < this.timer) {
          item.timer = 1;
        } else {
          item.timer = 2;
        }
      });
      this.total = res.count;
    },
  },
  mounted() {
    this.tzn_allYear();
  },
};
</script>

<style scoped>
.debris {
  width: 100%;
  margin-top: -157px;
  z-index: 8;
  background: transparent;
  position: relative;
}
.Aboutbg {
  position: absolute;
}
.title-top {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 auto;
  width: 1200px;
  height: 92px;
  background-image: url("~static/scenery/title.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  text-align: left;
  line-height: 92px;
  font-size: 24px;
  letter-spacing: 5px;
}
.home-icon {
  width: 20px;
  height: 20px;
  margin: 0 16px 0 53px;
}
.title-top > span:hover {
  cursor: pointer;
  color: #509ab1;
}
.content-middle {
  position: relative;
  width: 1275px;
  height: 2601px;
  background-image: url("~static/activityAll/listbgc.png");
  background-repeat: no-repeat;
  margin: 38px auto 0;
}
.titlt-box {
  position: relative;
}
.content-middle-item {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 145px;
  white-space: nowrap;
  letter-spacing: 3px;
  line-height: 250px;
  width: 452px;
  height: 250px;
  font-weight: bold;
  vertical-align: middle;
  text-align: center;
  background: url("~static/Hrbg.png");
  font-size: 35px;
  font-family: PangMenZhengDao-Cu;
  font-weight: 400;
  color: #509ab1;
}
.content-table {
  width: 1013px;
  position: absolute;
  top: 400px;
  right: 132px;
  left: 130px;
  /* background-color: pink; */
}
.content-table-item-box {
  display: flex;
  align-items: center;
  position: relative;
  height: 192px;
  width: 100%;
}

.content-table-item {
  position: relative;
  display: flex;
  align-items: center;
}
.content-table-left {
  position: relative;
  width: 114px;
  height: 67px;
  margin-top: 30px;
}
.content-table-left-time {
  width: 40px;
  height: 30px;
  font-size: 39px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  line-height: 58px;
}
.content-table-left-timer {
  position: absolute;
  top: 37px;
  left: 43px;
  width: 42px;
  height: 17px;
  font-size: 19px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  line-height: 28px;
}
.el-divider--horizontal {
  position: absolute;
  bottom: 0;
  left: 101px;
  margin: 0;
  width: 897px;
  height: 1px;
  background: #000000;
  opacity: 0.12;
}
.content-table-center {
  width: 646px;
  line-height: 37px;
}
.content-table-center > div {
  display: flex;
  align-items: center;
}
.content-table-center > div > img {
  width: 97px;
  height: 37px;
}
.content-table-center > div > span {
  width: 482px;
  height: 23px;
  font-size: 20px;
  font-family: Source Han Serif CN;
  font-weight: bold;
  color: #509ab1;
  line-height: 24px;
}

.content-table-center > p {
  margin-top: 11px;
  margin-left: 14px;
  width: 639px;
  height: 42px;
  font-size: 16px;
  font-family: Source Han Serif CN;
  font-weight: 400;
  color: #656565;
  line-height: 24px;
}
.content-table-right {
  position: relative;
  width: 170px;
  height: 127px;
  background-image: url("~static/activityAll/1.png");
  background-repeat: no-repeat;
  margin-left: 83px;
}

.content-table .oneps {
  background-image: url("~static/activityAll/2.png");
}
.content-table .twops {
  background-image: url("~static/activityAll/brige-icon.png");
}
.content-table-right > p {
  position: absolute;
  top: 68px;
  left: 18px;
  /* width: 68px; */
  width: 100px;
  height: 18px;
  font-size: 20px;
  font-family: PangMenZhengDao-Cu;
  font-weight: 400;
  color: #ffffff;
  line-height: 0px;
}

.pagination-box {
  display: flex;
  height: 36px;
  position: absolute;
  bottom: -75px;
  left: 270px;
}
.my-btn {
  width: 86px;
  height: 36px !important;
  text-align: center;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #969696;
  line-height: 0;
  background-color: #f0f0f0;
  margin-top: 2px;
  margin-right: 4px;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #1c9cb2;
}

/deep/.el-pagination.is-background .btn-next,
/deep/.el-pagination.is-background .el-pager li {
  margin: 0 5px;
  background-color: #f0f0f0;
  color: #606266;
  min-width: 36px;
  height: 36px;
  line-height: 36px;
  border-radius: 2px;
}
/deep/.el-pagination.is-background .btn-next {
  width: 86px;
  line-height: 36px !important;
}

/deep/.el-pagination span:not([class*="suffix"]) {
  /* width: 48px;
  height: 14px;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #646464; */
  line-height: 36px;
}

.allYearActivity {
  position: relative;
}

.Aboutbg {
  position: absolute;
}
</style>